/* You can add global styles to this file, and also import other style files */
@import url('assets/css/css-circular-prog-bar.css');

:root {
    --fontFamily: 'Roboto', sans-serif;
    --primaryColor: #007daa;
    --secondaryColor: #7360ff;
    --whiteColor: #ffffff;
    --blackColor: #000000;
    --headingColor: #171717;
    --paragraphColor: #6a6f73;
    --transition: all ease .5s;
    --fontSize: 16px;
    --borderRadius: 5px;
    --boxShadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
	--bgFormColor: #f0f0f0;
}
body {
    margin: 0;
    padding: 0;
    font: {
        size: var(--fontSize);
        family: var(--fontFamily);
    };
}
a {
    transition: var(--transition);
	color: var(--headingColor);
	text-decoration: none;

	&:hover {
        text-decoration: none;
        color: var(--primaryColor);
	}
	&:focus {
		text-decoration: none;
	}
}
button {
    outline: 0 !important; 
	box-shadow: none;
	border: none;
	padding: 0;

    &:focus {
        box-shadow: none;
    }
}
.pointer{
	cursor: pointer;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: var(--headingColor);
	font-weight: bold;
}
.d-table {
    width: 100%;
    height: 100%;
}
.d-table-cell {
    vertical-align: middle;
}
p {
	color: var(--paragraphColor);
    font-size: var(--fontSize);
	margin-bottom: 15px;
	line-height: 1.8;

	&:last-child {
        margin-bottom: 0;
    }
} 
img {
    max-width: 100%;
    height: auto;
}

.profil{
	object-fit: cover !important;
	border-radius: 50% !important;
	width: 150px !important;
	height: 150px !important;
}
.ptb-100 {
	padding: {
		top: 100px;
		bottom: 100px;
	};
}
.ptb-60 {
	padding: {
		top: 60px;
		bottom: 60px;
	};
}
.pt-60 {
    padding-top: 60px;
}
.pb-30 {
    padding-bottom: 30px;
}
.pt-100 {
    padding-top: 100px;
}
.pb-100 {
    padding-bottom: 100px;
}
.pt-70 {
    padding-top: 70px;
}
.pb-70 {
    padding-bottom: 70px;
}
.pb-40 {
    padding-bottom: 40px;
}
.mt-100 {
	margin-top: 100px;
}
.mt-minus-100 {
	margin-top: -100px;
}
.mt-30 {
	margin-top: 30px;
}
.mb-30 {
	margin-bottom: 30px;
}
.pl-15 {
	padding-left: 15px;
}
.pr-15 {
	padding-right: 15px;
}
.ml-15 {
	margin-left: 15px;
}
.mr-15 {
	margin-right: 15px;
}

/*bg-color*/
.bg-color-eee4dc {
	background-color: #eee4dc;
}
.bg-color-fdfcfc {
	background-color: #fdfcfc;
}
.bg-color-05122d {
	background-color: #05122d;
}
.bg-color-f2f8fa {
	background-color: #f2f8fa;
}
.bg-color-f2f7fa {
	background-color: #f2f7fa;
}
.bg-color-f5f1ee {
	background-color: #f5f1ee;
}
.bg-color-eee4dc {
	background-color: #eee4dc;
}

/*form-control*/
.form-select {
	cursor: pointer;
}
.form-control {
	width: 100%;
    height: 55px;
	font-size: 16px;
	border-radius: 0;
	padding: 10px 20px;
    color: var(--headingColor);
	border: 1px solid #d7d7d7;
	background-color: transparent;
	transition: var(--transition);

	&::placeholder {
		color: var(--paragraphColor);
	}
	&:focus {
		outline: 0;
		box-shadow: unset;
		border-color: var(--primaryColor);
	}
}
textarea {
    &.form-control {
        height: auto;
    }
}

/*default-btn*/
.default-btn {
	z-index: 1;
	overflow: hidden;
	text-align: center;
	position: relative;
	padding: 12px 35px;
	display: inline-block;
    color: var(--whiteColor);
    transition: var(--transition);
	background-color: var(--primaryColor);
	border: 1px solid var(--primaryColor);
	border-radius: 10px;
	font: {
		size: 15px;
		weight: 500;
	};
	i {
		top: 2px;
		margin-left: 5px;
		position: relative;
		font-size: 14px !important;
		color: var(--whiteColor) !important;
	}
	&::after  {
		left: 0;
		bottom: 0;
		height: 0;
		content: "";
		width: 100%;
		z-index: -1;
		position: absolute;
		transition: var(--transition);
		background-color: var(--headingColor);
	}
	&:hover {
		color: var(--whiteColor);

		&::after {
			top: 0;
			height: 100%;
			bottom: auto;
			animation: rotateIn 0.5s linear;
		}
	}
}

/*default-btn-with-border*/
.default-btn-border {
	z-index: 1;
	overflow: hidden;
	text-align: center;
	position: relative;
	padding: 12px 35px;
	display: inline-block;
    color: var(--primaryColor);
    transition: var(--transition );
	background-color: var(--whiteColor);
	border: 1px solid var(--primaryColor);
	border-radius: 10px;
	font: {
		size: 15px;
		weight: 500;
	};
	i {
		top: 2px;
		margin-left: 5px;
		position: relative;
		font-size: 14px !important;
		color: var(--whiteColor) !important;
	}
	&::after  {
		left: 0;
		bottom: 0;
		height: 0;
		content: "";
		width: 100%;
		z-index: -1;
		position: absolute;
		transition: var(--transition);
		background-color: var(--headingColor);
	}
	&:hover {
		color: var(--whiteColor);

		&::after {
			top: 0;
			height: 100%;
			bottom: auto;
			animation: rotateIn 0.5s linear;
		}
	}
}

/*read-more*/
.read-more {
	font-size: 16px;
	color: var(--headingColor);
	transition: var(--transition);
	background-color: transparent;

	i {
		position: relative;
		top: 3px;
	}
	&:hover {
		color: var(--primaryColor);
		letter-spacing: 1px;
	}
}

/*section-title*/
.section-title {
	max-width: 700px;
	text-align: center;
	position: relative;
	margin: -6px auto 50px;

	.top-title {
		display: block;
		font-size: 15px;
		margin-bottom: 8px;
		color: var(--primaryColor);
	}
	h2 {
		font-size: 35px;
		margin-bottom: 10px;

		span {
			color: var(--primaryColor);
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	p {
		max-width: 600px;
		margin: auto;

		span {
			color: var(--primaryColor);
		}
	}
	&.white-title {
		span {
			color: var(--primaryColor);
		}
		h2 {
			color: var(--whiteColor);
		}
		p {
			color: var(--whiteColor);
		}
	}
	&.left-title {
		margin-left: 0;
		max-width: 100%;
		text-align: start;

		h2 {
			margin-bottom: 0;
		}
	}
}

/*extra-css*/
.our-happy-student-slide {
	.owl-theme {
		.owl-nav {
			max-width: 822px;
			text-align: start;
			position: relative;
            margin: {
                top: 0;
                left: auto;
            };
			.owl-prev, .owl-next {
				background-color: #f5f1ee;
				color: var(--headingColor);
				position: absolute;
				border-radius: 50px;
				text-align: center;
				line-height: 40px;
				overflow: hidden;
				height: 40px;
				width: 40px;
				z-index: 1;
				bottom: 0;
				margin: 0;
				left: 0;

				&::after  {
					left: 0;
					bottom: 0;
					height: 0;
					width: 100%;
					content: "";
					z-index: -1;
					position: absolute;
					transition: var(--transition);
					background-color: var(--primaryColor);
				}
				i {
					left: 0;
					right: 0;
					top: 50%;
					font-size: 25px;
					position: absolute;
					transform: translateY(-50%);
				}
				&:hover {
					color: var(--whiteColor);

					&::after {
						top: 0;
						height: 100%;
						bottom: auto;
						animation: rotateIn 0.5s linear;
					}
				}
			}
			.owl-next {
				left: 50px;

				i {
					right: -1px;
				}
			}
		}
	}
}
.instructors-slide {
	.owl-theme {
		.owl-nav {
			transform: translateY(-55px);
			position: absolute;
			margin-top: 0;
			bottom: -18px;
    		left: -140px;

			.owl-prev, .owl-next {
				background-color: #f5f1ee;
				color: var(--headingColor);
				border-radius: 50px;
				position: relative;
				text-align: center;
				line-height: 40px;
				overflow: hidden;
				height: 40px;
				width: 40px;
				z-index: 1;

				&::after  {
					left: 0;
					bottom: 0;
					height: 0;
					z-index: -1;
					content: "";
					width: 100%;
					position: absolute;
					transition: var(--transition);
					background-color: var(--primaryColor);
				
				}
				i {
					left: 0;
					right: 0;
					top: 50%;
					font-size: 25px;
					position: absolute;
					transform: translateY(-50%);
				}
				&:hover {
					color: var(--whiteColor);

					&::after {
						top: 0;
						height: 100%;
						bottom: auto;
						animation: rotateIn 0.5s linear;
					}
				}
			}
		}
	}
}
.courses-tabs {
	.nav-tabset {
		text-align: end;
		padding-left: 0;
		list-style-type: none;
		margin: {
			top: -84px;
			bottom: 50px;
		};
		.nav-tab {
			display: inline-block;
			margin: {
				right: 12px;
				left: 12px;
			};
			&:last-child {
				margin-right: 0;
			}
			&:first-child {
				margin-left: 0;
			}
			span {
				transition: var(--transition);
				position: relative;
				cursor: pointer;
				display: block;

				&::before {
					right: 0;
					width: 0;
					bottom: 0;
					content: "";
					height: 1px;
					position: absolute;
					transition: var(--transition);
					background-color: var(--primaryColor);
				}
			}
			&.active {
				span {
					color: var(--primaryColor);

					&::before {
						width: 100%;
					}
				}
			}
		}
	}
}
.categories-slide {
	cursor: e-resize;
	
	.owl-theme {
		.owl-nav {
			margin-top: 0;

			.owl-prev, .owl-next {
				top: 50%;
				z-index: 1;
				left: -45px;
				width: 30px;
				height: 30px;
				overflow: hidden;
				line-height: 30px;
				text-align: center;
				position: absolute;
				border-radius: 50px;
				color: var(--headingColor);
				background-color: #f5f1ee;
				transform: translateY(-55px);

				&::after  {
					left: 0;
					bottom: 0;
					height: 0;
					content: "";
					z-index: -1;
					width: 100%;
					position: absolute;
					transition: var(--transition);
					background-color: var(--primaryColor);
				
				}
				i {
					left: 0;
					right: 0;
					top: 50%;
					font-size: 25px;
					position: absolute;
					transform: translateY(-50%);
				}
				&:hover {
					color: var(--whiteColor);

					&::after {
						top: 0;
						height: 100%;
						bottom: auto;
						animation: rotateIn 0.5s linear;
					}
				}
			}
			.owl-next {
				left: auto;
				right: -45px;
			}
		}
	}
	&.style-two {
		.owl-theme {
			.owl-nav {
				margin-top: 0;

				.owl-prev, .owl-next {
					background-color: #cbdae1;
				}
			}
		}
	}
}
.faq-accordion {
	.panel {
		margin-bottom: 20px;
		border: 1px solid #e2dad3;
        background-color: var(--whiteColor);
	}
    .panel-group {
		.dropup {
			.panel-heading {
				.panel-title {
					a {
						color: var(--whiteColor);
						background-color: var(--primaryColor);

						&::before {
							content: "\F1AF";
						}
					}
				}
			}
		}
	}
    .panel-group {
        .panel-heading {
            .panel-title {
                margin-bottom: 0;

                a {
                    border: 0;
                    display: block;
                    text-align: start;
                    box-shadow: unset;
					position: relative;
                    color: var(--blackColor);
					padding: 15px 40px 15px 20px;
					background-color: var(--whiteColor);
                    font: {
                        size: 20px;
                        weight: 500;
                    };
					&::before {
						top: 50%;
						right: 20px;
						content: "\ea13";
						position: absolute;
						transform: translateY(-50%);
						font: {
							size: 20px;
							family: 'remixicon';
						};
					}
                }
            }
        }
        .panel-collapse {
			border-top: 1px solid #eeeeee;
			padding: 20px;

            p {
                font-size: 15px;

                strong {
                    color: var(--headingColor);
					font-weight: 500;
                }
                a {
                    color: var(--primaryColor);

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            ul, ol {
                margin-bottom: 15px;

                li {
                    font-size: 15px;
                    margin-bottom: 12px;
                    color: var(--paragraphColor);
                    
                    strong {
                        color: var(--headingColor);
						font-weight: 500;
                    }
                    a {
                        color: var(--primaryColor);

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .collapse {
            &.in {
                display: block;
            }
        }
    }
}
.page-content {
	text-align: center;
	max-width: 800px;
	margin: {
		left: auto;
		right: auto;
	};
	.pages-link {
		padding-left: 0;
		margin-bottom: 0;
		list-style-type: none;

		li {
			color: var(--paragraphColor);
			display: inline-block;
			position: relative;
			margin: {
				left: 13px;
				right: 13px;
			};
			&::before {
				top: 0;
				width: 1px;
				content: "";
				height: 100%;
				right: -13px;
				position: absolute;
				transform: rotate(15deg);
				background-color: var(--primaryColor);
			}
			&:last-child {
				margin-right: 0;

				&::before {
					display: none;
				}
			}
			&:first-child {
				margin-left: 0;
			}
			&.active {
				font-weight: bold;
				color: var(--primaryColor);
			}
		}
	}
	h2 {
		font-size: 40px;
		margin-bottom: 18px;
	}
	.default-btn {
		margin-bottom: 15px;
	}
	&.text-start {
		margin: {
			left: 0;
			right: 0;
		};
		.review {
			margin-bottom: 15px;

			i {
				line-height: 1;
				font-size: 18px;
				color: #f6b429;
				margin-right: 3px;
			}
			span {
				position: relative;
				font-size: 15px;
				top: -2px;
			}
		}
		.instructors-status {
			padding-left: 0;
			margin-bottom: 15px;
			list-style-type: none;

			li {
				display: inline-block;
				position: relative;
				padding-left: 15px;
				font-size: 15px;

				&:first-child {
					padding-left: 0;
				}
				img {
					position: relative;
					margin-right: 5px;
					top: -2px;
				}
				i {
					color: var(--primaryColor);
					position: relative;
					margin-right: 0;
					top: 2px;
				}
			}
		}
	}
	
}
.page-banner-area {
	background-color: #f5f1ee;
}
.pagination-area {
	display: flex;
    margin-top: 15px;
    text-align: center;
	align-items: center;
	justify-content: center;

    .page-numbers {
        //width: 42px;
        height: 42px;
        margin: 0 5px;
		padding: 0 10px;
        line-height: 42px;
        text-align: center;
        border-radius: 25%;
        display: inline-block;
        color: var(--blackColor);
        box-shadow: 0 2px 5px 0 #d8dde6;
        background-color: var(--whiteColor);
        font: {
            size: 16px;
            weight: 700;
        };
        &.current, &:hover {
            background: var(--whiteColor);
            color: var(--primaryColor);
        }
    }
}
.overview {
	.nav-tabset {
		padding-left: 0;
		text-align: center;
		margin-bottom: 40px;
		list-style-type: none;
		background-color: #f5f1ee;

		.nav-tab {
			display: table-cell;
			border-right: 1px solid #eee4dc;

			span {
				transition: var(--transition);
				position: relative;
				cursor: pointer;
				display: block;
				padding: {
					top: 20px;
					left: 73px;
					right: 73px;
					bottom: 20px;
				};
				&::before {
					left: 0;
					width: 0;
					bottom: 0;
					height: 2px;
					content: "";
					position: absolute;
					background-color: #01adee;
					transition: var(--transition);
				}
				&:hover {
					background-color: var(--whiteColor);

					&::before {
						width: 100%;
					}
				}
			}
			&.active {
				span {
					background-color: var(--whiteColor);

					&::before {
						width: 100%;
					}
				}
			}
			&:last-child {
				border-right: none;
			}
		}
	}
}
.related-courses {
	.owl-theme {
		.owl-dots {
			margin-top: 0 !important;
			position: absolute;
			line-height: 1;
			top: -55px;
			right: 0;

			.owl-dot {
				span {
					width: 16px;
					height: 16px;
					margin: 0 4px;
					position: relative;
					border-radius: 50%;
					background: transparent;
					transition: var(--transition);
					border: 1px solid var(--primaryColor);
				}
				&:hover {
					span {
						border-color: var(--primaryColor);

						&::before {
							background-color: var(--primaryColor);
						}
					}
				}
				&.active {
					span {
						border-color: var(--primaryColor);
						position: relative;

						&::before {
							left: 0;
							top: 0;
							right: 0;
							bottom: 0;
							content: '';
							margin: 3px;
							border-radius: 50%;
							position: absolute;
							background-color: var(--primaryColor);
							transition: var(--transition);
						}
					}
				}
			}
		}
	}
}
.become-instructors {
	.nav-tabset {
		padding-left: 0;
		text-align: center;
		margin-bottom: 30px;
		list-style-type: none;
		background-color: #f5f1ee;

		.nav-tab {
			display: table-cell;
			border-right: 1px solid #eee4dc;

			span {
				transition: var(--transition);
				position: relative;
				cursor: pointer;
				display: block;
				padding: {
					top: 20px;
					left: 60px;
					right: 60px;
					bottom: 20px;
				};
				&::before {
					left: 0;
					width: 0;
					bottom: 0;
					height: 2px;
					content: "";
					position: absolute;
					background-color: #01adee;
					transition: var(--transition);
				}
				&:hover {
					background-color: var(--whiteColor);

					&::before {
						width: 100%;
					}
				}
			}
			&.active {
				span {
					background-color: var(--whiteColor);

					&::before {
						width: 100%;
					}
				}
			}
			&:last-child {
				border-right: none;
			}
		}
	}
}
.coming-soon-content {
    .timer {
        .countdown {
            div {
                justify-content: center;
                overflow: hidden;
    
                div {
                    width: 100px;
                    height: 80px;
                    line-height: 55px;
                    color: var(--primaryColor) !important;
					border: 4px solid var(--primaryColor);
                    background-color: transparent !important;
                    font: {
                        size: 30px !important;
                        weight: 700 !important;
                    };
                    margin: {
                        left: 10px;
                        right: 10px;
                    };
                }
            }
        }
    }
}
.my-courses-tabs {
	.nav-tabset {
		padding-left: 0;
		margin-bottom: 30px;
		list-style-type: none;

		.nav-tab {
			display: inline-block;
			margin: {
				right: 15px;
				left: 15px;
			};
			&:last-child {
				margin-right: 0;
			}
			&:first-child {
				margin-left: 0;
			}
			span {
				transition: var(--transition);
				position: relative;
				cursor: pointer;
				display: block;

				&::before {
					right: 0;
					width: 0;
					bottom: 0;
					content: "";
					height: 1px;
					position: absolute;
					transition: var(--transition);
					background-color: var(--primaryColor);
				}
			}
			&.active {
				span {
					color: var(--primaryColor);

					&::before {
						width: 100%;
					}
				}
			}
		}
	}
}
@keyframes movebounce {
    0% {
        transform: translateY(0px); 
    }
    50% {
        transform: translateY(20px); 
    }
    100% {
        transform: translateY(0px); 
    } 
}
@keyframes moveleftbounce {
    0% {
        transform: translateX(0px); 
    }
    50% {
        transform: translateX(20px); 
    }
    100% {
        transform: translateX(0px); 
    } 
}
@keyframes border-transform {
    0%,100% {
        border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
    } 
	14% {
        border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
    } 
	28% {
        border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
    } 
	42% {
        border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
    } 
	56% {
        border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
    } 
	70% {
        border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
    } 
	84% {
        border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
    } 
}
@keyframes rotate3d {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}
@keyframes movescale {
    0% {
        transform: scale(0.9);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.9);
    }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {

	body {
		font-size: 14px;
	}
	.ptb-100 {
		padding: {
            top: 60px;
            bottom: 60px;
        };
	}
	.pt-100 {
		padding-top: 60px;
	}
	.pb-100 {
		padding-bottom: 60px;
	}
	.pb-70 {
		padding-bottom: 30px;
    }
    .pl-15 {
        padding-left: 0;
    }
    .pr-15 {
        padding-right: 0;
    }
    .mt-minus-100 {
        margin-top: 0;
    }
    .ml-15 {
        margin-left: 0;
    }
    .mr-15 {
        margin-right: 0;
    }
    p {
        font-size: 14px;
    }
    .section-title {
		margin: {
			bottom: 30px;
			top: -4px;
		};
        h2 {
            font-size: 23px;
        }
		&.left-title {
			text-align: center;
		}
		.default-btn {
			display: none;
		}
    }
    .default-btn {
        padding: 10px 25px;
        font-size: 14px;
    }
	.form-control {
		height: 50px;
		font-size: 14px;
		padding: {
			left: 15px;
			right: 15px;
		};
	}

    .instructors-slide {
        .owl-theme {
            .owl-nav {
				transform: translateY(0);
				position: relative;
				bottom: 0;
				left: 0;
				margin: {
					bottom: 20px;
					top: -10px;
				};
				.owl-prev, .owl-next {
					top: 0;
					left: 0;
					width: 30px;
					height: 30px;
					line-height: 30px;
					position: relative;
					transform: translateY(0);
					margin: {
						top: 0;
						bottom: 0;
					};
				}
				.owl-nav {
					margin: {
						left: 0;
						right: 3px;
					};
				}
				.owl-next {
					left: 0;
					right: 0;
					margin: {
						right: 0;
						left: 3px;
					};
				}
            }
        }
    }
    .our-happy-student-slide {
        .owl-theme {
            .owl-nav {
                margin-top: 20px;

                .owl-prev, .owl-next {
                    position: relative;
                    margin-right: 10px;
                }
                .owl-next {
                    left: 0;
                }
            }
        }
    }
	.page-content {
		max-width: 100%;
		
		.pages-link {
			li {
				line-height: 1;
			}
		}
		h2 {
			font-size: 25px;
			margin-bottom: 10px;
		}
		&.text-start {
			margin: {
				left: 0;
				right: 0;
			};
			.review {
				margin-bottom: 15px;
	
				i {
					line-height: 1;
					font-size: 18px;
					color: #f6b429;
					margin-right: 3px;
				}
				span {
					position: relative;
					font-size: 15px;
					top: -2px;
				}
			}
			.instructors-status {
				padding-left: 0;
				margin-bottom: 15px;
				list-style-type: none;
	
				li {
					display: inline-block;
					position: relative;
					padding-left: 15px;
					font-size: 15px;
	
					&:first-child {
						padding-left: 0;
					}
					img {
						position: relative;
						margin-right: 5px;
						top: -2px;
					}
					i {
						color: var(--primaryColor);
						position: relative;
						margin-right: 0;
						top: 2px;
					}
				}
			}
		}
		
	}
    .pagination-area {
        margin-top: 0;

		.page-numbers {
			width: 35px;
			height: 35px;
			font-size: 14px;
			line-height: 35px;
		}
    }
	.categories-slide {
		.owl-theme {
			.owl-nav {
				margin: {
					bottom: 20px;
					top: -10px;
				};
				.owl-prev, .owl-next {
					top: 0;
					left: 0;
					width: 30px;
					height: 30px;
					line-height: 30px;
					position: relative;
					transform: translateY(0);
					margin: {
						top: 0;
						bottom: 0;
					};
				}
				.owl-nav {
					margin: {
						left: 0;
						right: 3px;
					};
				}
				.owl-next {
					left: 0;
					right: 0;
					margin: {
						right: 0;
						left: 3px;
					};
				}
			}
		}
	}
	.courses-tabs {
		.nav-tabset {
			text-align: center;
			margin: {
				top: 0;
				bottom: 15px;
			};
			.nav-tab {
				margin-bottom: 13px;
			}
		}
	}
	.faq-accordion {
		.panel-group {
			.panel-heading {
				.panel-title {
					a {
						padding: 12px 40px 12px 12px;
						font-size: 15px;

						&::before {
							right: 15px;
							font-size: 15px;
						}
					}
				}
			}
			.panel-collapse {
				padding: 12px;
	
				p {
					font-size: 14px;
				}
				ul, ol {	
					li {
						font-size: 14px;
					}
				}
			}
		}
	}
	.become-instructors {
		.nav-tabset {
			margin-bottom: 30px;
	
			.nav-tab {
				span {
					padding: {
						top: 15px;
						left: 15px;
						right: 15px;
						bottom: 15px;
					};
				}
			}
		}
	}
	.overview {
		.nav-tabset {
			margin-bottom: 30px;
	
			.nav-tab {
				display: block;
				border-bottom: 1px solid #eee4dc;
	
				span {
					padding: {
						top: 15px;
						left: 20px;
						right: 20px;
						bottom: 15px;
					};
				}
			}
		}
	}
	.coming-soon-content {
		.timer {
			.countdown {
				div {
					div {
						line-height: unset;
						padding: 0 !important;
						width: auto !important;
						height: auto !important;
						border: none !important;
						font-size: 20px !important;
						margin: {
							left: 5px;
							right: 5px;
						};
					}
				}
			}
		}
	}
	.my-courses-tabs {
		.nav-tabset {
			margin-bottom: 10px;
	
			.nav-tab {
				margin: {
					bottom: 12px;
					right: 6px;
					left: 6px;
				};
			}
		}
	}

}

/* Min width 576px to Max width 767px */
@media only screen and (min-width: 576px) and (max-width: 767px) {

	.container-fluid {
		max-width: 540px;
	}

}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {

	body {
		font-size: 15px;
	}
	.ptb-100 {
		padding: {
            top: 60px;
            bottom: 60px;
        };
	}
	.pt-100 {
		padding-top: 60px;
	}
	.pb-100 {
		padding-bottom: 60px;
	}
	.pb-70 {
		padding-bottom: 30px;
    }
    .pl-15 {
        padding-left: 0;
    }
    .pr-15 {
        padding-right: 0;
    }
    .mt-minus-100 {
        margin-top: 0;
    }
    .ml-15 {
        margin-left: 0;
    }
    .mr-15 {
        margin-right: 0;
    }
    p {
        font-size: 15px;
    }
	.section-title {
		margin: {
			bottom: 40px;
			top: -5px;
		};
        h2 {
            font-size: 25px;
        }
		&.left-title {
			text-align: center;
		}
    }
	.default-btn {
        padding: 10px 30px;
        font-size: 14px;
    }

    .our-happy-student-slide {
        .owl-theme {
            .owl-nav {
                margin-top: 20px;

                .owl-prev, .owl-next {
                    position: relative;
                    margin-right: 10px;
                }
                .owl-next {
                    left: 0;
                }
            }
        }
    }
    .page-content {
        text-align: center;

        .pages-link {
            li {
                font-size: 15px;
            }
        }
        .page-title {
            margin-bottom: 15px;

            &.text-left {
                text-align: center;
            }
            h2 {
                font-size: 30px;
            }
        }
    }
	.pagination-area {
        margin-top: 0;
    }
	.categories-slide {
		.owl-theme {
			.owl-nav {
				margin: {
					bottom: 20px;
					top: -10px;
				};
				.owl-prev, .owl-next {
					top: 0;
					left: 0;
					position: relative;
					transform: translateY(0);
					margin: {
						top: 0;
						bottom: 0;
					};
				}
				.owl-nav {
					margin: {
						left: 0;
						right: 3px;
					};
				}
				.owl-next {
					left: 0;
					right: 0;
					margin: {
						right: 0;
						left: 3px;
					};
				}
			}
		}
	}
	.courses-tabs {
		.nav-tabset {
			text-align: center;
			margin: {
				top: 0;
				bottom: 15px;
			};
			.nav-tab {
				margin-bottom: 13px;
			}
		}
	}
    .instructors-slide {
        .owl-theme {
            .owl-nav {
				transform: translateY(0);
				position: relative;
				bottom: 0;
				left: 0;
				margin: {
					bottom: 20px;
					top: -10px;
				};
				.owl-prev, .owl-next {
					top: 0;
					left: 0;
					position: relative;
					transform: translateY(0);
					margin: {
						top: 0;
						bottom: 0;
					};
				}
				.owl-nav {
					margin: {
						left: 0;
						right: 3px;
					};
				}
				.owl-next {
					left: 0;
					right: 0;
					margin: {
						right: 0;
						left: 3px;
					};
				}
            }
        }
    }
	.overview {
		.nav-tabset {
			.nav-tab {
				span {
					padding: {
						top: 17px;
						left: 55px;
						right: 55px;
						bottom: 17px;
					};
				}
			}
		}
	}

}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {

	.pl-15 {
        padding-left: 0;
    }
    .pr-15 {
        padding-right: 0;
    }
    .ml-15 {
        margin-left: 0;
    }
    .mr-15 {
        margin-right: 0;
    }
	.section-title {
        h2 {
            font-size: 35px;
        }
    }

	.our-happy-student-slide {
        .owl-theme {
            .owl-nav {
                max-width: 460px;
            }
        }
    }
	.categories-slide {
		.owl-theme {
			.owl-nav {
				margin: {
					bottom: 20px;
					top: -10px;
				};
				.owl-prev, .owl-next {
					top: 0;
					left: 0;
					position: relative;
					transform: translateY(0);
					margin: {
						top: 0;
						bottom: 0;
					};
				}
				.owl-nav {
					margin: {
						left: 0;
						right: 3px;
					};
				}
				.owl-next {
					left: 0;
					right: 0;
					margin: {
						right: 0;
						left: 3px;
					};
				}
			}
		}
	}
	.courses-tabs {
		.nav-tabset {
			.nav-tab {
				font-size: 15px;
				margin: {
					right: 10px;
					left: 10px;
				};
			}
		}
	}
	.overview {
		.nav-tabset {
			.nav-tab {
				span {
					padding: {
						top: 20px;
						left: 43px;
						right: 43px;
						bottom: 20px;
					};
				}
			}
		}
	}
	.become-instructors {
		.nav-tabset {
			.nav-tab {
				span {
					padding: {
						top: 20px;
						left: 25px;
						right: 25px;
						bottom: 20px;
					};
				}
			}
		}
	}

}

/* Min width 1200px to Max width 1399px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {

	.categories-slide {
		.owl-theme {
			.owl-nav {
				.owl-prev, .owl-next {
					left: -40px;
				}
				.owl-next {
					right: -40px;
					left: auto;
				}
			}
		}
	}
	.overview {
		.nav-tabset {
			.nav-tab {
				span {
					padding: {
						top: 20px;
						left: 43px;
						right: 43px;
						bottom: 20px;
					};
				}
			}
		}
	}
	.become-instructors {
		.nav-tabset {
			.nav-tab {
				span {
					padding: {
						top: 20px;
						left: 25px;
						right: 25px;
						bottom: 20px;
					};
				}
			}
		}
	}

}

/* Min width 1550px */
@media only screen and (min-width: 1550px) {}

// spinner
.loading-text {
    top: 50% !important;
}


.img-of-course {
	width: 100% !important;	
	height: 200px !important;
	object-fit: cover !important;
	border-radius: 5px;
}

.former-avatar {
	img {
		height:30px !important;
	width: 30px !important;
	border-radius: 50% !important;
	object-fit: cover !important;
	}
}

.miniature-profile{
	height:30px !important;
	width: 30px !important;
	border-radius: 50% !important;
	object-fit: cover !important;
}

ngb-typeahead-window{
	width: 100% !important;
}

.owl-carousel .owl-item .miniature-profile {
	display: inline !important;
}

.slider-description p{
	color: #fff !important;
}

.modal-content {
	background-color: transparent !important;
	border: none !important;
	.modal-header button {
		background-color: transparent !important;
	}
}


.height-qcm{
	height: 40px !important;
}

.height-20 {
	height: 20px !important;	
}

.height-30 {
	height: 30px !important;	
}

.height-40 {
	height: 40px !important;	
}

.cc-revoke.cc-bottom {
    display: none !important;
}


.course-btn-primary {
	top: -2px;
    font-size: 14px;
    position: relative;
    display: inline-flex; /* Use flexbox */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    color: var(--whiteColor);
    padding: 8px 15px;
    background-color: var(--primaryColor);;
    transition: var(--transition);
	font-weight: 500;
	border: 0.5px solid var(--primaryColor);
	border-radius: 10px;
	height: 45px;

	i {
		margin-right: 5px; /* Adjust as needed */
        font-size: 16px;
		color: var(--whiteColor) !important;
	}
	&:hover {
		background-color: var(--blackColor);
	}
}

.course-btn-secondary {
	top: -2px;
    font-size: 14px;
    position: relative;
    display: inline-flex; /* Use flexbox */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    color: var(--blackColor);
    padding: 8px 15px;
    background-color: #ffffff;
    transition: var(--transition);
	font-weight: 500;
	border: 0.5px solid var(--primaryColor);
	border-radius: 10px;
	height: 45px;

	i {
		margin-right: 5px; /* Adjust as needed */
        font-size: 16px;
		color: var(--blackColor) !important;
	}
	&:hover {
		background-color: var(--primaryColor);
		color: var(--whiteColor);
		i {
			color: var(--whiteColor) !important;
			transition: var(--transition);
		}
	}
}


.course-btn-black {
	top: -2px;
    font-size: 14px;
    position: relative;
    display: inline-flex; /* Use flexbox */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    color: var(--whiteColor);
    padding: 8px 15px;
    background-color: var(--blackColor);
    transition: var(--transition);
	font-weight: 500;
	border: 0.5px solid var(--primaryColor);
	border-radius: 10px;
	height: 45px;


	&:hover {
		background-color: var(--whiteColor);
		color: var(--blackColor);
	}
}